import MXCache from 'Common/CacheManager/MXCache';
import { AppMode } from 'Common/Enums/metadata.enum';
import {
    AuthResponse,
    UserAuthResponse,
} from 'Common/Models/authresponse.model';
import MXLogger from 'Common/MXLogger';
import { getMobileOSType } from 'Common/Utils/CheckDevice';
import { createStore, setMany, UseStore } from 'idb-keyval';
import {
    getMobileAuthCookie,
    getMobileAuthCookieFromCb,
    getPlatformNamefromCookie,
} from 'utils/getMobileCookies';
import {
    handleMobileExceptions,
    handleMobileToSetUserAuthToken,
} from 'utils/mobileCallbackHandle';
import API from '../API';

enum SocketInfraVersionEnum {
    v1 = 1,
    v2 = 2,
}

const setUserAuthResponseToMXCache = (userAuthResponse: UserAuthResponse) => {
    MXCache.SetCacheItem(
        MXCache.LeadMergeFeildsKey,
        userAuthResponse.leadMergeFields,
        480
    );
    MXCache.SetCacheItem(
        MXCache.IsBotEnabled,
        userAuthResponse.isBOTEnabled,
        480
    );
    MXCache.SetCacheItem(
        MXCache.UserDetailKey,
        userAuthResponse.userFields,
        480
    );
    MXCache.SetCacheItem(
        MXCache.LeadPhoneFeildsKey,
        userAuthResponse.leadPhoneFields,
        480
    );
    MXCache.SetCacheItem(
        MXCache.LicensedFeaturesKey,
        userAuthResponse.licensedFeatures,
        480
    );
    MXCache.SetCacheItem(
        MXCache.HistorySizeKey,
        userAuthResponse.historySize,
        480
    );

    MXCache.SetCacheItem(
        MXCache.SystemUserKey,
        userAuthResponse.systemUser,
        480
    );

    MXCache.SetCacheItem(
        MXCache.IsServiceCloudEnabled,
        userAuthResponse.isServiceCloudEnabled ?? false,
        600
    );

    MXCache.SetCacheItem(
        MXCache.SocketInfraVersionKey,
        userAuthResponse.socketInfraVersion || SocketInfraVersionEnum.v1,
        480
    );
    MXCache.SetCacheItem(
        MXCache.AvailabilitySettings,
        userAuthResponse.availabilitySettings,
        600
    );
    MXCache.SetCacheItem(
        MXCache.UserPreference,
        userAuthResponse.userPreference.recentContactsFilter,
        600
    );
    MXCache.SetCacheItem(
        MXCache.TokenExpiryTime,
        userAuthResponse.expiresOn,
        600
    );
    MXCache.SetCacheItem(MXCache.UserRole, userAuthResponse.role, 600);
    MXCache.SetCacheItem(
        MXCache.TemplateMessagesFilter,
        userAuthResponse.userPreference.templateMessagesFilter,
        600
    );
};

export default class AuthService extends API {
    static authUrl = process.env.REACT_APP_AUTH_BASE_URL || '';

    static apiBaseUrl =
        `${process.env.REACT_APP_API_SERVICE_BASE_URL}auth/` || '';

    static RegionID = process.env.REACT_APP_REGION_ID || '1';

    clearInterval = 0;

    mode: number;

    authorization?: string | null;

    converseStore: UseStore;

    constructor(mode: number, authorization?: string) {
        super(AuthService.authUrl, false);
        this.mode = mode;
        this.authorization = authorization;

        this.converseStore = createStore('Converse_DB', 'Converse_Auth');
    }

    async isAuthorized(): Promise<boolean> {
        let authToken = API.GetAuthToken();

        if (this.mode === AppMode.LsqMobile && authToken === null) {
            let mobileUserData = '';
            const mobileAuthCookie = getMobileAuthCookie();
            const platFormNameFromCoookie =
                getPlatformNamefromCookie(mobileAuthCookie) ||
                getMobileOSType();
            const platFormName = getMobileOSType();
            if (
                platFormName === 'android' ||
                platFormNameFromCoookie === 'android'
            ) {
                mobileUserData = (
                    window as any
                ).LSQAndroidClient?.fetchConverseUserAuthResponse();
            } else if (
                platFormName === 'ios' ||
                platFormNameFromCoookie === 'ios'
            ) {
                const mobileUserDataPromise = (
                    window as any
                ).webkit?.messageHandlers?.fetchConverseUserAuthResponse?.postMessage(
                    'fetchConverseUserAuthResponse'
                );

                mobileUserData = await mobileUserDataPromise
                    .then((res: any) => res)
                    .catch((e: any) => '');
            }
            const userData = mobileUserData ? JSON.parse(mobileUserData) : null;

            if (
                userData &&
                userData.converseUserAuthResponse &&
                Object.keys(userData.converseUserAuthResponse).length > 0
            ) {
                const { converseUserAuthResponse } = userData;
                authToken = {
                    Token: (converseUserAuthResponse as UserAuthResponse).token,
                    ExpiresOn: (converseUserAuthResponse as UserAuthResponse)
                        .expiresOn,
                };
                API.SetAuthToken(authToken);
                setUserAuthResponseToMXCache(
                    converseUserAuthResponse as UserAuthResponse
                );
                MXCache.SetCacheItem(
                    MXCache.ConnectorConfigKey,
                    userData?.connectorConfigKey,
                    30
                );
            }
        }

        const Token = authToken?.Token;
        const ExpiresOn = authToken?.ExpiresOn;

        // set token in IDB
        setMany(
            [
                ['Token', Token],
                ['ExpiresOn', ExpiresOn],
            ],
            this.converseStore
        );

        if (authToken === null || authToken === undefined) {
            MXLogger.debug('authToken is empty, checking for auth cookie');
            MXCache.RemoveOneItem(MXCache.UnreadChatCount);
            MXCache.RemoveOneItem(MXCache.Carter);
            return this.authorize();
        }
        if (authToken.Token.length > 0) {
            const dt = new Date();
            if (dt > new Date(authToken.ExpiresOn)) {
                MXLogger.debug('token expired');
                API.RemoveAuthToken();
                MXCache.RemoveOneItem(MXCache.UnreadChatCount);
                MXCache.RemoveOneItem(MXCache.Carter);
                return this.authorize();
            }
        }
        // this.isauth({ tokenExpiry: authToken.ExpiresOn })
        if (!(await this.isauth())) {
            MXLogger.debug('is auth was not successfull');
            MXCache.RemoveOneItem(MXCache.UnreadChatCount);
            MXCache.RemoveOneItem(MXCache.Carter);
            return this.authorize();
        }

        return true;

        // return false;
    }

    private async authorize(): Promise<boolean> {
        try {
            let staticAuth = '';
            let dynamicAuth = '';

            if (this.mode === AppMode.LsqMobile) {
                const mobileAuth = getMobileAuthCookie();
                const { STATICAUTH = '', DYNAMICAUTH = '' } = mobileAuth;
                dynamicAuth = DYNAMICAUTH;
                staticAuth = STATICAUTH;
                if (
                    !(DYNAMICAUTH || STATICAUTH) &&
                    getMobileOSType() === 'ios'
                ) {
                    const {
                        STATICAUTH: mobileStaticAuth = '',
                        DYNAMICAUTH: mobileDynamicAuth = '',
                    } = (await getMobileAuthCookieFromCb()) as any;

                    dynamicAuth = mobileDynamicAuth;
                    staticAuth = mobileStaticAuth;
                }
            }

            if (
                this.mode === AppMode.LsqMobile &&
                !(dynamicAuth || staticAuth)
            ) {
                return false;
            }

            if (
                document.referrer != null &&
                (document.referrer.toLowerCase().indexOf('/home/signin') !=
                    -1 ||
                    document.referrer.toLowerCase().indexOf('https://login') !=
                        -1)
            ) {
                MXCache.Clear();
            }
            MXCache.SetCacheItem(MXCache.AppModeKey, this.mode);

            const userAuthResponse = this.Responsify<UserAuthResponse>(
                await this.instance.get('authorize', {
                    withCredentials: true,
                    headers: {
                        LSQ_RID: AuthService.RegionID,
                        APP_MODE: `${this.mode}`,
                        Authorization: this.authorization as any,
                        ...(this.mode === AppMode.LsqMobile && {
                            ...(staticAuth && { STATIC_AUTH: staticAuth }),
                            ...(dynamicAuth && {
                                DYNAMIC_AUTH: dynamicAuth,
                            }),
                        }),
                    },
                })
            );

            if (
                userAuthResponse &&
                userAuthResponse.token &&
                userAuthResponse.token.length
            ) {
                if (this.mode === AppMode.LsqMobile) {
                    handleMobileToSetUserAuthToken(userAuthResponse);
                }
                API.SetAuthToken({
                    Token: userAuthResponse.token,
                    ExpiresOn: userAuthResponse.expiresOn,
                } as AuthResponse);

                // set token in IDB
                setMany(
                    [
                        ['Token', userAuthResponse.token],
                        ['ExpiresOn', userAuthResponse.expiresOn],
                    ],
                    this.converseStore
                );
                setUserAuthResponseToMXCache(userAuthResponse);
                this.loopCheckToken();
                return true;
            }

            this.Logout();

            return false;
        } catch (e) {
            MXLogger.error(e);

            if (this.mode === AppMode.LsqMobile) {
                handleMobileExceptions(
                    (e as any).response?.data?.exceptionDetails
                );
            }
            return false;
        }
    }

    private async isauth(): Promise<boolean> {
        // const dt = new Date();
        // if (
        //     [
        //         AppMode.Widget,
        //         AppMode.MarvinMobile,
        //         AppMode.MarvinWidget,
        //     ].includes(this.mode) &&
        //     new Date(tokenExpiry) > dt
        // ) {
        //     return true;
        // }
        const res = this.Responsify<any>(
            await new API(AuthService.apiBaseUrl, true, true).instance.get(
                'isauthorized',
                {
                    withCredentials: true,
                    headers: {
                        LSQ_RID: AuthService.RegionID,
                    },
                }
            )
        );
        if (!res) return false;
        this.loopCheckToken();
        return true;
    }

    private checkTokenExpiryAndAuthorize = async (authToken: AuthResponse) => {
        const dt = new Date();
        const extraTime = new Date(dt.getTime() + 3 * 60000);

        if (extraTime > new Date(authToken.ExpiresOn)) {
            MXLogger.debug('expired');
            API.RemoveAuthToken();

            await this.authorize();
        }
    };

    private loopCheckToken = () => {
        if (this.clearInterval === 0) {
            this.clearInterval = window.setInterval(async () => {
                const authToken = API.GetAuthToken();
                if (authToken) {
                    await this.checkTokenExpiryAndAuthorize(authToken);
                }
            }, 5000);
        }
    };
}
